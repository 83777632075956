import React, { ChangeEvent, FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, noop } from 'lodash';
import { useFormik } from 'formik';
// Import Constants
import l from 'constants/localizationLabels';
// Import Services
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Validators
import { CJThisDayOfYearOptionValidationSchema } from 'validators/schemas.validation';
// Import Components
import { ExtendedInput } from '@geneui/components';

interface ThisDayOfYearFormValues {
    offset: number | null;
}

interface Props {
    option?: { Required?: boolean };
    defaultValue?: { offset?: ThisDayOfYearFormValues['offset'] };
    getUpdate: (data: { offset: ThisDayOfYearFormValues['offset'] }) => void;
}

const ThisDayOfYear: FC<Props> = (props) => {
    const { defaultValue, getUpdate, ...rest } = props;
    const { t } = useTranslation();

    const formik = useFormik<ThisDayOfYearFormValues>({
        initialValues: {
            offset: defaultValue?.offset || 0,
        },
        onSubmit: noop,
        validationSchema: CJThisDayOfYearOptionValidationSchema(rest?.option),
    });
    const { values, errors, setFieldValue } = formik;
    const { offset } = values;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target?.value.replace('.', '');
        const tempValue = isNil(value) || isEmpty(value) ? null : +value;

        setFieldValue('offset', tempValue);
    };

    useEffect(() => {
        getUpdate({ offset });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);

    return (
        <ExtendedInput
            type="number"
            value={offset}
            onChange={handleChange}
            placeholder={l.Offset}
            label={l.Offset}
            labelAppearance="swap"
            isValid={!errors?.offset}
            errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.offset)}
        />
    );
};

export default memo(ThisDayOfYear);
