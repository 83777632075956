import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
// Import Constants
import { l } from 'constants/common';
// Import Components
import { CJInfoBlockLabelValueWrapper } from 'components/CustomerJourney/infoBlocks';
import { isNil } from 'lodash';

// create interface Props for data?.value?.params?.offset
interface Props {
    data?: {
        value?: {
            params?: {
                offset: number;
            };
        };
    };
}

const CJDTThisDayOfYearInfoBlock: FC<Props> = ({ data }) => {
    const { t } = useTranslation();
    const offset = data?.value?.params?.offset;

    return <CJInfoBlockLabelValueWrapper label={t(l.Offset)} value={isNil(offset) ? null : t(`${offset}`)} />;
};

export default memo(CJDTThisDayOfYearInfoBlock);
